////////// COLOR SYSTEM //////////

// $blue:              #5E50F9;
// $indigo:            #6610f2;
// $purple:            #6a008a;
// $pink:              #E91E63;
// $red:               #f96868;
// $orange:            #f2a654;
// $yellow:            #f6e84e;
// $green:             #46c35f;
// $teal:              #58d8a3;
// $cyan:              #57c7d4;
// $black:             #000;
// $white:             #ffffff;
// $white-smoke:       #f2f7f8;
// $violet:            #41478a;
// $darkslategray :    #2e383e;
// $dodger-blue :      #3498db;


// $colors: (
//   blue:             $blue,
//   indigo:           $indigo,
//   purple:           $purple,
//   pink:             $pink,
//   red:              $red,
//   orange:           $orange,
//   yellow:           $yellow,
//   green:            $green,
//   teal:             $teal,
//   cyan:             $cyan,
//   white:            $white,
//   gray:             #434a54,
//   gray-light:       #aab2bd,
//   gray-lighter:     #e8eff4,
//   gray-lightest:    #e6e9ed,
//   gray-dark:        #12151e,
//   black:            #000000
// );

$tk-black: #131313;

$colors: (
    tk-balck: $tk-black
);



$theme-colors: (
  primary:         #fa9de8,
  secondary:       #B4B4B4,
  success:         #00d25b,
  info:            #A5A5A5,
  warning:         #ffab00,
  danger:          #fc424a,
  light:           $white,
  dark:            #0d0d0d
);

$sizes: ( 25: 25%, 50: 50%, 75: 75%, 100: 100%, );


////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg-color: $tk-black;
$footer-bg: $content-bg-color;
$footer-color: color(dark);
$border-color: transparent;
$circle-border: rgb(26, 57, 85); 
$sidebar-color-start: #1b1428;
$sidebar-color-end: #1c1c1c;

$main-panel-color-start:rgba(50,50,50,0.7) ;
$main-panel-color-end: rgba(20,20,20,0.7);
$main-panel-color-bg: linear-gradient(90deg, $main-panel-color-start 0%, $main-panel-color-end 100%);

//$card-bg: #191c24;
$card-bg: $sidebar-color-start;
$card-bg-expanded-color-start: #fa9d68;
$card-bg-expanded-color-end: #f26b8d;
$card-description-color: #bfbfbf;
$card-title-color: map-get($theme-colors,"secondary");
$sidebar-wrapper-bg: $main-panel-color-start;

////////// COLOR VARIABLES //////////

$content-bg-image: '../images/background.jpg';

////////// FONTS//

//$type1: 'Rubik', sans-serif;
$type1: 
  // system-ui,
  // -apple-system, 
  // "Segoe UI", 
  // Roboto, 
  "Helvetica Neue", 
  system-ui,
  -apple-system, 
  Arial,
  sans-serif,;

$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

$default-font-size: .875rem; // 14px as base font size

$text-muted: #6c7293;
$body-color: #ffffff;

////////// FONT VARIABLES //////////


////////// CONTENT WRAPPER //////////

$content-padding: 1.875rem 1.75rem;
$content-padding-responsive: 1rem 1rem 0.5rem 1rem;
$content-right-side-spacer: 180px;

////////// CONTENT WRAPPER //////////

////////// SIDEBAR ////////
$sidebar-width-lg: 244px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

//$sidebar-bg: linear-gradient(90deg, $sidebar-color-start,  $sidebar-color-end);
$sidebar-bg: $sidebar-color-start;
//$sidebar-menu-color: $text-muted;
$sidebar-menu-color: map-get($theme-colors,"secondary");
//$sidebar-menu-active-bg: darken($sidebar-color-start, 5%);
$sidebar-menu-active-bg: linear-gradient(135deg, $card-bg-expanded-color-start, $card-bg-expanded-color-end);
$sidebar-menu-active-color: black;
$sidebar-menu-hover-bg: linear-gradient(135deg, $card-bg-expanded-color-start, $card-bg-expanded-color-end);
//$sidebar-menu-hover-color: white;
$sidebar-menu-hover-color: black;
$sidebar-submenu-color: $sidebar-menu-color;
$sidebar-submenu-hover-bg: initial;
$sidebar-submenu-hover-color: #ffffff;

$sidebar-menu-icon-color: #bba8bff5;
$sidebar-menu-arrow-color: rgba($text-muted, 0.36);

$sidebar-menu-font-size: 0.9375rem;
$sidebar-menu-padding-y-top: 2.5rem;
$sidebar-menu-padding-y-bottom: 0.8rem;
//$sidebar-menu-padding-x: 1.188rem;
$sidebar-menu-padding-x: 1.488rem;
$rtl-sidebar-submenu-padding: 0 1.5rem 0 0;

$sidebar-submenu-font-size: 0.855rem;
$sidebar-submenu-item-padding: 0.5rem 0.35rem;

$sidebar-icon-font-size: 1rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: .75rem 1.625rem .75rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg: darken($sidebar-color-start, 5%);

///////// SIDEBAR ////////

///////// NAVBAR ////////

$navbar-height: 70px;
//$navbar-bg: linear-gradient(90deg, #1c1c20,  #1c1c1c);
$navbar-bg: $sidebar-bg;
$navbar-menu-color: #ffffff;
$navbar-font-size: .9375rem;
$navbar-icon-font-size: 1.125rem;
$navbar-box-shadow: 20px 19px 34px -15px rgba(0,0,0,0.5);

///////// NAVBAR ////////
/// 
/// ///////// BUTTONS ////////

$button-fixed-width: 150px;
$btn-padding-y: .375rem;
$btn-padding-x: .75rem;
$btn-line-height: 1;

$btn-padding-y-xs: .5rem;
$btn-padding-x-xs: .75rem;

$btn-padding-y-sm: .6rem;
$btn-padding-x-sm: .6rem;

$btn-padding-y-md: 0.9rem;
$btn-padding-x-md: 3rem;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;

$btn-font-size: 0.9375rem;
$btn-font-size-xs: 0.625rem;
$btn-font-size-md: 0.725rem;
$btn-font-size-sm: 0.725rem;
$btn-font-size-lg: 0.875rem;

$btn-border-radius: .1875rem;
$btn-border-radius-xs: .1875rem;
$btn-border-radius-sm: .1875rem;
$btn-border-radius-lg: .1875rem;

$btn-min-width-md: 16rem;

///////// BUTTONS ////////

/////////  FORMS /////////

$input-bg: #2b1a3e;
$placeholder-color: #6f7071;
$input-border-radius: 2px;
$input-border-color: $border-color;
$input-placeholder-color: lighten($input-bg, 15%);
$input-placeholder-font-size: 0.875rem;
$input-font-size: 0.875rem;

$input-padding-y: 0.56rem;
$input-padding-x: 0.75rem;
$input-line-height: 1;


$input-padding-y-xs:    .5rem;
$input-padding-x-xs:    .75rem;

$input-padding-y-sm:    .50rem;
$input-padding-x-sm:    .81rem;

$input-padding-y-lg:    .94rem;
$input-padding-x-lg:    1.94rem;

$input-height:          2.875rem;
$input-height-sm:       2.575rem;
$input-height-lg:       3.175rem;

$form-select-bg: $sidebar-color-start;
$form-select-border-color: $form-select-bg;
$form-select-color: white;
$form-select-border-radius: 1.25rem;
$form-select-color: map-get($theme-colors,"secondary");

///////// FORMS /////////

////////  DROPDOWNS ///////

$dropdown-bg: $card-bg;
$dropdown-color: $body-color;
$dropdown-item-hover-color: $white;
$dropdown-border-color: $border-color;
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-link-hover-bg: darken($card-bg, 3%);
$dropdown-header-color: $body-color;
$dropdown-menu-box-shadow: none;

////////  DROPDOWNS ///////


//////// TABLES ////////

//$table-accent-bg: #000000;
$table-hover-bg:  darken($sidebar-color-start, 4%);
$table-cell-padding: .9375rem;
$table-border-color: #3E3E3E;
$table-hover-color: inherit;
$table-font-size: 0.75rem;
//////// TABLES ////////

////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$border-property: 1px solid $border-color;
$card-spacing-y: 2.5rem;
$card-padding-y-top: 1rem;
$card-padding-y-bottom: 1.75rem;
$card-padding-y: 1.2rem;
$card-padding-x: 1.5625rem;
$card-border-radius: 1.5rem;
$card-description-color: #76838f;
$grid-gutter-width: 1.5rem;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
$card-bg-varient: #fff;
$border-width: 1px;
////////// OTHER VARIABLES //////////

////////// MODALS VARIABLES //////////

$modal-inner-padding:               0.9375rem;
$modal-dialog-margin:               10px;
$modal-dialog-margin-y-sm-up:       30px;
$modal-title-line-height:           $line-height-base;
$modal-content-bg:                  $sidebar-color-start;
$modal-content-box-shadow-xs:       0 3px 9px rgba($black,.5);
$modal-content-box-shadow-sm-up:    0 5px 15px rgba($black,.5);

$modal-backdrop-bg:                 $black;
$modal-backdrop-opacity:            .5;

$modal-header-border-color:         $border-color;
$modal-content-border-color:        $border-color;
$modal-footer-border-color:         $border-color;

$modal-header-border-width:         $border-width;
$modal-content-border-width:        $border-width;
$modal-footer-border-width:         $border-width;

$modal-header-padding-x:              26px;
$modal-header-padding-y:              25px;

$modal-body-padding-x:                26px;
$modal-body-padding-y:                35px;

$modal-footer-padding-x:              31px;
$modal-footer-padding-y:              15px;

$modal-lg:                          90%;
$modal-md:                          500px;
$modal-sm:                          300px;
$modal-transition:                  transform .4s ease;

////////// MODALS VARIABLES //////////

/////////  PAGINATION VARIABLES //////////
$pagination-bg: #2b1a3e;
$pagination-color: white;
$pagination-active-bg: #fa9d68;
$pagination-hover-bg: #fa9d68;
$pagination-focus-bg: #fa9d68;
$pagination-disabled-bg: #1b1428;
$pagination-hover-color: black;
$pagination-active-color: black;
$pagination-active-border-color: #fa9d68;
/////////  PAGINATION VARIABLES //////////

////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
////////   CALENDAR  //////////////

///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
///////// SETTINGS PANEL ////////

////////// Vendor file asset path //////////
$mdi-font-path:        "~@mdi/font/fonts";
$fa-font-path : '~font-awesome/fonts/';
$flag-icon-css-path : '~flag-icon-css/flags/';
$simple-line-font-path : '~simple-line-icons/fonts/';


///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

$bullet-line-list-shape-bg: color(white) !default;

///////// Profile page ///////
$stats_widget_icon_background: linear-gradient(135deg, $card-bg-expanded-color-start, $card-bg-expanded-color-end)
///////// Profile page ///////