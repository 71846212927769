/* Layouts */

.navbar {
  &.fixed-top {
    + .page-body-wrapper {
      padding-top: $navbar-height;
    }
  }
}

//Fixed sidebar
.sidebar-fixed {
    @media(min-width: 992px) {
        .sidebar-wrapper {
        //.sidebar {
          position: fixed;
          max-height: auto;
          .nav {
              max-height: 100vh;
              overflow: auto;
              position: relative;
              &.sub-menu {
              max-height: none;
              }
          }
        }
        .page-body-wrapper {
          margin-left: $sidebar-width-lg + 40px;
        }
        &.sidebar-icon-only {
          .page-body-wrapper {
            margin-left: $sidebar-width-icon;
          }
        }
    }
}