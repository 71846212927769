.name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.specs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.specs-container p {
    font-size: 13pt;
    line-height: 15pt;
    font-weight: 300;
}