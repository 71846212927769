/* Cards */

.card {
    border-radius: $card-border-radius;
    box-shadow: inset 0px 6px 14px -9px map-get($theme-colors, "secondary");
    .card-body {
        padding: $card-padding-y $card-padding-x;
        + .card-body {
            padding-top: 1rem;
        }
        &.body-sm {
            padding: 0.7rem;
        }
    }
    .card-title {
        color: $card-title-color;
        margin-bottom: 1.125rem;
        padding: 1rem;
        text-transform: capitalize;

        &.card-title-shadow {
            -webkit-box-shadow: 0px 7px 12px -7px #000000; 
            box-shadow: 0px 7px 12px -7px #000000;

            border-top-right-radius: 1.5rem;
            border-top-left-radius: 1.5rem;
        }
    } 
    
    .card-subtitle {
        @extend .text-gray;
        font-weight: normal;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;

    }
    .card-description {
        color: $card-description-color;
        font-weight: $font-weight-light;
    }
    // &.card-outline-success {
    //     border: 1px solid theme-color("success");
    // }
    // &.card-outline-primary {
    //     border: 1px solid theme-color("primary");
    // }
    // &.card-outline-warning {
    //     border: 1px solid theme-color("warning");
    // }
    // &.card-outline-danger {
    //     border: 1px solid theme-color("danger");
    // }
    &.card-extended-height {
        min-height: 19rem;
    }
    &.card-rounded {
        @include border-radius(5px);
    }

    &.card-faded {
        background: #b5b0b2;
        border-color: #b5b0b2;
    }
    &.card-circle-progress {
        color: $white;
        text-align: center;
    }
    &.card-img-holder {
        position: relative;
        .card-img-absolute {
            position: absolute;
            top:0;
            right: 0;
            height: 100%;
        }
    }
    &.secondary {
        background-color: #2b1a3e;
        box-shadow: none !important;
        min-height: 15rem;
    }
}
