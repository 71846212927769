

$rbt-token-background-color: #fa9d68 !default;
$rbt-token-color: black;
$rbt-token-active-background-color: #fa9d68;

.rbt-input-multi .rbt-input-main {
    color: black;
}

.rbt-input-multi .rbt-input-hint {
    color: darken(white, 40%) !important;
}

.rbt-menu .dropdown-item:hover {
    color: white;
}

@import '~react-bootstrap-typeahead/css/Typeahead.scss';