.player-card {
    @extend .card;
    //background: linear-gradient(321deg, #282832,  #282828);
    &.card-expanded{
        background: linear-gradient(90deg, $card-bg-expanded-color-start, $card-bg-expanded-color-end);
        color: black;
    }
    .menu-arrow {
        font-size: 2rem;
        padding: 0;
        color: $card-bg-expanded-color-start;
        line-height: 0.5;
    }
    .menu-arrow-expanded {
        color: black;
    }
    .card-body {
        padding: 1rem 1rem 1rem 1.5rem;
    }

    .form-control {
        background-color: unset;
        height: calc(1.9rem + 2px);
        font-size: 0.7rem;
        border-radius: 0.75rem;
        border-color: map-get($map: $theme-colors, $key: 'secondary');
    }

    input::-webkit-input-placeholder {
        font-size: 0.7rem;
        vertical-align: middle;
        line-height: 100px;
    }
    
    input::-moz-placeholder {
        font-size: 0.7rem;
        vertical-align: middle;
        line-height: 100px;
    }
    
    input:-ms-input-placeholder {
        font-size: 0.7rem;
        vertical-align: middle;
        line-height: 100px;
    }
    
    input::placeholder {
        font-size: 0.7rem;
        vertical-align: middle;
        line-height: 100px;
    }

    // .btn {
    //     width: 6rem;
    //     border-radius: 0.75rem;
    //     padding: 0.275rem 0.75rem;
    //     font-size: 0.75rem;
    // }
    .btn {
        @extend .btn-primary;
        @extend .btn-rounded;
        @extend .btn-sm; 
    }
}