.nav-tabs-container {
    margin-top: 2rem;
    .nav-tabs {
        border: none;
        .nav-link.active,
        .nav-item.show .nav-link {
            background: $sidebar-color-start;
            border: none;
            //border-top-left-radius: 0.25rem;
            //border-top-right-radius: 0;
        }
        .nav-item {
            flex: 1;
            border-radius: 0;
        }
        .nav-link {
            width: 100%;
            text-align: start;
            margin-bottom: -2px;
            background-color: $input-bg;
            color: map-get($theme-colors,"secondary");
        }
        .nav-item:first-child .nav-link {
            border-top-left-radius: 10px;
        }
        .nav-item:last-child .nav-link {
            border-top-right-radius: 10px;
        }
    }
    #tabs-team-tabpane-team {
        &.active.show {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
        }
    }
    .tab-pane {
        min-height: 30rem;
        background: $sidebar-color-start;
        padding: 2rem 0.5rem 0.5rem 0.5rem;
    }
}