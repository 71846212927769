.form-check {
    position: relative;
    display: block;
    margin-top: 15px;
    margin-bottom: 10px;
    padding-left: 0;

    .form-check-label {
        display: block;
        padding-left: 0.625rem;
        font-size: $default-font-size;
        line-height: 1.5;
        color: $text-muted;
        .rtl & {
            padding-left: 0;
            padding-right: 1.875rem;
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            .rtl & {
                left: auto;
                right: 0;
            }
            margin-left: 0;
            margin-top: 0;
            z-index: 1;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
        }
        input[type="checkbox"] {
            + .input-helper {
                &:before,
                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    .rtl & {
                        left: auto;
                        right: 0;
                    }
                }
                &:before {
                    content: "";
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    border: solid map-get($theme-colors, "secondary");
                    border-width: 2px;
                    @include transition(all);
                    transition-duration: 0s;
                    -webkit-transition-duration: 250ms;
                    transition-duration: 250ms;
                    position: absolute;
                    top: 2px;
                    left: 0;
                }

                &:after {
                    @include transition(all);
                    transition-duration: 0s;
                    -webkit-transition-duration: 250ms;
                    transition-duration: 250ms;
                    font-family: Material Design Icons;
                    opacity: 0;
                    filter: alpha(opacity=0);
                    -webkit-transform: scale(0);
                    -ms-transform: scale(0);
                    -o-transform: scale(0);
                    transform: scale(0);
                    content: '\F12C';
                    font-size: 0.875rem;
                    font-weight: bold;
                    color: $white;
                    top: 1px;
                }
            }
            &:checked {
                +.input-helper {
                    &:before {
                        background: map-get($theme-colors, "secondary");
                        border-width: 0;
                    }
                    &:after {
                        width: 18px;
                        opacity: 1;
                        line-height: 18px;
                        filter: alpha(opacity=100);
                        -webkit-transform: scale(1);
                        -ms-transform: scale(1);
                        -o-transform: scale(1);
                        transform: scale(1);
                        color: black;
                    }
                }
            }

            &:disabled {
                + .input-helper {
                &:before {
                    border-color: $border-color;
                }
                }

                &:checked {
                + .input-helper {
                    &:after {
                    background: $border-color;
                    color: $white;
                    }
                }
                }
            }
        }
        input[type="radio"] {
            +.input-helper {
                &:before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    .rtl & {
                        left: auto;
                        right: 0;
                    }
                    border: solid map-get($theme-colors, "secondary");
                    border-width: 2px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    @include transition(all);
                    transition-duration: 0s;
                    -webkit-transition-duration: 250ms;
                    transition-duration: 250ms;
                }

                &:after {
                    content: "";
                    width: 8px;
                    height: 8px;
                    background: $white;
                    border-radius: 50%;
                    top: 6px;
                    left: 6px;
                    .rtl & {
                        left: auto;
                        right: 6px;
                    }
                    -webkit-transition: all;
                    -o-transition: all;
                    transition: all;
                    transition-duration: 0s;
                    -webkit-transition-duration: 250ms;
                    transition-duration: 250ms;
                    opacity: 0;
                    filter: alpha(opacity=0);
                    -webkit-transform: scale(0);
                    -ms-transform: scale(0);
                    -o-transform: scale(0);
                    transform: scale(0);
                    position: absolute;
                }
            }

            &:checked {
                +.input-helper {
                    &:before {
                        background: map-get($theme-colors, "secondary");
                        border-width: 0;
                    }

                    &:after {
                        opacity: 1;
                        line-height: 1.5;
                        filter: alpha(opacity=100);
                        -webkit-transform: scale(1);
                        -ms-transform: scale(1);
                        -o-transform: scale(1);
                        transform: scale(1);
                    }
                }
            }

            &:disabled {
                + .input-helper {
                &:before {
                    border-color: $border-color;
                }
                }

                &:checked {
                + .input-helper {
                    &:before {
                    background: $border-color;
                    }

                    &:after {
                    background: $white;
                    }
                }
                }
            }
        }
    }
}
  
@each $color, $value in $theme-colors {
    .form-check-#{$color} {
        &.form-check {
            label {
                input[type="checkbox"],
                input[type="radio"] {
                    +.input-helper {
                        &:before {
                            border-color: map-get($theme-colors, $color);
                        }
                    }
                    &:checked {
                        +.input-helper {
                            &:before {
                                background: $value;
                            }
                        }
                    }
                }
            }
        }
    }
}
  
.form-check-muted {
    &.form-check {
        label {
            input[type="checkbox"],
            input[type="radio"] {
                +.input-helper {
                &:before {
                    border-color: $text-muted;
                }
                }
                &:checked {
                +.input-helper {
                    &:before {
                    background: $text-muted;
                    }
                }
                }
            }
        }
    }
}
  

.form-check-input {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    &:checked {
        background-color: map-get($theme-colors, "secondary");
        border-color: map-get($theme-colors, "secondary");
    }
}