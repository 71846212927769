.league-logo {
    height: 43px;
    width: 43px;
}

.logo-placeholder {
    background-color: #e0e0e0;
}

.icon-placeholder {
    font-size: 43px;
}

.team-logo {
    height: 43px;
    width: 43px;
    background-color: white;
    padding: 2px;
    border-radius: 50px;
}

.big {
    height: 115px;
    width: 115px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
}

.team-row {
    cursor: pointer;
}