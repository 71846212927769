/* Utilities */

.grid-margin {
  margin-bottom: $grid-gutter-width;
}
.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}
.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}
.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}
.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}
.img-xxxl {
  width: 300px;
  height: 300px;
  padding: 0px;
  object-fit: cover;
}
.img-xxl {
  width: 150px;
  height: 150px;
}
.img-xl {
  width: 115px;
  height: 115px;
}
.img-lg {
  width: 92px;
  height: 92px;
}
.img-md {
  width: 60px;
  height: 60px;
}
.img-sm {
  width: 43px;
  height: 43px;
}
.img-xs {
  width: 35px;
  height: 35px;
}
.img-ss {
  width: 26px;
  height: 26px;
}
.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);
  >.card{
    width: 100%;
    min-width: 100%;
  }
}

.svg-thumbnail {
  height: 100px;
  width: 100px;
  max-height: 100px;
  border-radius: 5px;
  padding: 8px;
  display: -webkit-flex; /* Safari */  
  display: flex;
  -webkit-align-items: center; /* Safari 7.0+ */
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;

  svg {
    width: 10rem;
    //height: 140px;
  }
}

// .border-right-sm {
//   @media (min-width: 576px) {
//     border-right: $border-width solid $border-color;
//   }
// }
// .border-right-md {
//   @media (min-width: 768px) {
//     border-right: $border-width solid $border-color;
//   }
// }
// .border-right-lg {
//   @media (min-width: 992px) {
//     border-right: $border-width solid $border-color;
//   }
// }

// .border-left-sm {
//   @media (min-width: 576px) {
//     border-left: $border-width solid $border-color;
//   }
// }
// .border-left-md {
//   @media (min-width: 768px) {
//     border-left: $border-width solid $border-color;
//   }
// }
// .border-left-lg {
//   @media (min-width: 992px) {
//     border-left: $border-width solid $border-color;
//   }
// }

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
    .border#{$infix}-right {    border-right: $border-width solid $border-color !important; }
    .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
    .border#{$infix}-left {     border-left: $border-width solid $border-color !important; }

    .border#{$infix}-top-0 {    border-top: 0 !important; }
    .border#{$infix}-right-0 {  border-right: 0 !important; }
    .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
    .border#{$infix}-left-0 {   border-left: 0 !important; }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-small {
  font-size: 12px;
}

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-light {
  font-weight: $font-weight-medium;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-normal {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}
.image-grouped {
  display: flex;

  .text-avatar,
  img {
    @extend .img-ss;
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $card-bg;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width";

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;

    }
  }

  // .text-avatar {
  //   @extend .bg-inverse-primary;
  //   color: theme-color(primary);
  //   font-size: 11px;
  //   font-weight: 600;
  // }
}
.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.display-wrapper {
  display: flex;
  .wrap-auto{
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
  .flex-wrap {
    flex-wrap: wrap
  }
  .flex-no-wrap {
    flex-wrap: nowrap;
  }
  &.wrap-center {
    align-items: center;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-start {
    justify-content: start;
  }
  &.justify-end {
    justify-content: end;
  }
  &.wrap-column-center {
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  &.wrap-column-start-center {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
  &.wrap-row-start-center {
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  &.wrap-row-center-center {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &.wrap-column-start-start {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }
  &.wrap-column-end-center {
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }
  &.wrap-row-space-between-center {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.position-absolute {
  position: absolute;

  &.absolute-center {
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
  
  &.left {
    left: 0;
  }
  
  &.right {
    right: 0;
  }
  
  &.bottom {
    bottom: 0;
  }
  
  &.top {
    top: 0;
  }

  &.center-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.aligner-wrapper {
  position: relative;
  
  .absolute {
    position: absolute;
  
    &.absolute-center {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }
    
    &.left {
      left: 0;
    }
    
    &.right {
      right: 0;
    }
    
    &.bottom {
      bottom: 0;
    }
    
    &.top {
      top: 0;
    }

    &.center-content {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}
.v-strock-2 {
  width: 3px;
}

.bg-gray-dark {
  background: color(gray-dark);
}

.list-no-bullet{
  list-style: none;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {width: $length !important;}
      .w-#{$breakpoint}-auto {width: auto !important;}
    }
  }
}

/* Video */
.video-container {
	width: 100%;
}

/* padding-top sets the overall height */
.video-wrapper {
    padding-top: 56.25%;
    height: 0px;
    position: relative;
}

/* final iframe uses 100% width & height */
.video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
