/* Sidebar */
$sidebar-border-radius: 20px;

.sidebar-wrapper {
  background: $sidebar-wrapper-bg;
  height: 100%;
  z-index: 15;
}

.sidebar {
    //min-height: calc(100vh - #{$navbar-height});
    min-height: calc(100vh - 50px);
    background: $sidebar-bg;
    font-weight: normal;
    padding: 0;
    margin: 20px;
    border-radius: $sidebar-border-radius;
    width: $sidebar-width-lg;
    z-index: 11;
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  
    .sidebar-brand-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      // background: $sidebar-bg;
      width: $sidebar-width-lg;
      height: $navbar-height;
      margin-top: 15px;
      border-top-left-radius: $sidebar-border-radius;
      border-top-right-radius: $sidebar-border-radius;
      
      @media (max-width: 991px) {
        width: 55px;
      }
      .sidebar-brand {
        color: lighten(#12151e, 20%);
        font-size: 1.5rem;
        line-height: 48px;
        margin-right: 0;
        //padding: 0rem 0rem 0rem 1.3rem;
        padding: $sidebar-menu-padding-y-top 10px $sidebar-menu-padding-y-bottom $sidebar-menu-padding-x;
        width: 100%;

        &:active,
        &:focus,
        &:hover {
            color: lighten(#12151e, 10%);
        }

        img {
          //width: calc(#{$sidebar-width-lg} - 120px );
          max-width: 100%;
          height: 50px;
          margin: auto;
          vertical-align: middle;
        }
        &.brand-logo-mini {
          display: none;
          img {
            width: calc(#{$sidebar-width-icon} - 50px );
            max-width: 100%;
            height: 28px;
            margin: auto;
          }
        }
      }
    }
  
    .nav {
      overflow: hidden;
      flex-wrap: nowrap;
      flex-direction: column;
      margin-bottom: 60px;
      //padding-top: $navbar-height;
      padding-top: 50px;
      padding-left: 3px;
      @media (max-width:992px) {
        padding-top: 0;
      }
  
      .nav-item {
        @include transition-duration(0.25s);
        transition-property: background;
        -webkit-transition-property: background;
        padding-right: 20px;
        .collapse {
          z-index: 999;
        }
  
        .nav-link {
          @include display-flex;
          @include align-items(center);
          white-space: nowrap;
          padding: $sidebar-menu-padding-y-bottom 10px $sidebar-menu-padding-y-bottom $sidebar-menu-padding-x;
          color: $sidebar-menu-color;
          @include transition-duration(0.45s);
          transition-property: color;
          -webkit-transition-property: color;
          height: 46px;
          border-radius: 0px 100px 100px 0px;
          cursor: pointer;
          
          i {
            color: inherit;
            &.menu-icon {
              font-size: $sidebar-icon-font-size;
              line-height: 1;
              margin-left: auto;
              .rtl & {
                margin-left: 0;
                margin-right: auto;
              }
              color: $sidebar-menu-icon-color;
              &:before {
                vertical-align: middle;
              }
            }
            &.menu-arrow {
              font: normal normal normal 24px/1 "Material Design Icons";
              line-height: 1;
              font-size: $sidebar-icon-font-size;
              margin-left: auto;
              .rtl & {
                margin-left: 0;
                margin-right: auto;
              }
              color: $sidebar-menu-arrow-color;
              &:before{
                content: "\f140";
                font-size: inherit;
                color: inherit;
              }
            }
          }
          .menu-title {
            color: inherit;
            display: inline-block;
            font-size: $sidebar-menu-font-size;
            line-height: 1;
            vertical-align: middle;
          }
          .badge {
            margin-right: auto;
            margin-left: 1rem;
          }
        }
  
        &.active {
          > .nav-link {
            background: $sidebar-menu-active-bg;
            position: relative;
            &:before {
              content: "";
              width: 3px;
              height: 100%;
              background: theme-color(primary);
              display: inline-block;
              position: absolute;
              left: 0;
              top: 0;
            }
            .menu-title {
              color: $sidebar-menu-active-color; 
              font-weight: bold;       
            }
            i {
              color: #ffffff;
            }
          }
        }
        &.nav-profile {
          .nav-link {
            height: auto;
            line-height: 1;
            border-top: 0;
            padding: 1.25rem 0;
            .nav-profile-image {
              width: 44px;
              height: 44px;
              img {
                width: 44px;
                height: 44px;
                border-radius: 100%;
              }
            }
            .nav-profile-text {
              margin-left: 1rem;
              .rtl & {
                margin-left: auto;
                margin-right: 1rem;
              }
            }
            .nav-profile-badge {
              font-size: 1.125rem;
              margin-left: auto;
              .rtl & {
                margin-left: 0;
                margin-right: auto;
              }
            }
          }
        }
        &.sidebar-actions {
          margin-top: 1rem;
          .nav-link {
            border-top: 0;
            display: block;
            height: auto;
          }
          &:hover {
            background: initial;
            .nav-link {
              color: initial;
            }
          }
        }
        &.profile {
          padding-right: 0px;
          .rtl & {
            padding-right: 10px;
          }
          .profile-desc {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0.6rem 1.17rem;
            line-height: 1.25;
            .profile-name {
              margin-left: 1rem;
              .rtl & {
                margin-left: 0;
                margin-right: 1rem;
              }
              span {
                font-size: 12px;
                color: $text-muted;
                white-space: nowrap;
              }
              h5 {
                white-space: nowrap;
              }
            } 
            .profile-pic {
              display: flex;
              align-items: center;
              .count-indicator {
                position: relative;
                .count-symbol,
                .count-number {
                  position: absolute;
                  border-radius: 100%;
                  border: 2px solid $navbar-bg;
                }
                .count-symbol {
                  top: 17px;
                  right: -3px;
                  width: 10px;
                  height: 10px;
                }
                .count-number {
                  min-width: 14px;
                  height: 14px;
                  font-size: .5rem;
                  color: $white;
                  bottom: 16px;
                  right: -5px;
                  line-height: 1;
                  text-align: center;
                }
                &:after {
                  display: none;
                }
                .count {
                  position: absolute;
                  left: 66%;
                  width: 10px;
                  height: 10px;
                  color: $white;
                  border-radius: 100%;
                  text-align: center;
                  font-size: .625rem;
                  line-height: 1.5;
                  top: 26px;
                  border: 2px solid $border-color;
                }
              }
            }
            .mdi-dots-vertical {
              font-size: 20px;
              color: $text-muted;
            }
            .dropdown-menu {
              padding: 0;
              margin-top: 1.25rem;
              background: white;
              .dropdown-item {
                padding: 11px 13px;
                color: map-get($theme-colors, "secondary");
                &.preview-item {
                  align-items: center;
                  .preview-thumbnail {
                    .preview-icon {
                      width: 30px;
                      height: 30px;
                      i {
                        font-size: 0.875rem;
                      }
                    }
                  }
                }
                &:hover,
                &:focus {
                  color: map-get($theme-colors, "secondary");
                  background: inherit;
                }
              }
              .dropdown-divider {
                margin: 0;
              }
            }
          }
        }
        &.nav-category {
          padding: 0.5rem 1.188rem;
          line-height: 1;
          .nav-link {
            color: $text-muted;
            font-weight: $font-weight-medium;
            font-size: 14px;
            font-weight: 500;
            height: auto;
            padding-left: 0;
            cursor: default;
          }
        }
        .menu-icon {
          margin-right: 0.5rem;
          font-size: 0.8125rem;
          line-height: 1;
          background: rgba($text-muted, 0.2);
          width: 31px;
          height: 31px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          i {
            font-size: 0.875rem;
          }
        }
      }
      &.sub-menu {
        margin-bottom: 0;
        margin-top:0;
        padding: 0 0 0 3.25rem;
        list-style: none;
  
        .nav-item {
          padding: 0;
          .nav-link {
            color: $sidebar-submenu-color;
            padding: $sidebar-submenu-item-padding;
            position: relative;
            font-size: $sidebar-submenu-font-size;
            line-height: 1;
            height: auto;
            border-top: 0;
            &.active {
              color: $sidebar-menu-active-color;
              background: transparent;
            }
            &:hover {
              color: $sidebar-submenu-hover-color;
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
      &:not(.sub-menu) {
        >.nav-item {
          &:hover {
            &:not(.nav-category):not(.account-dropdown) {
              >.nav-link {
                background: $sidebar-menu-hover-bg;
                color: $sidebar-menu-hover-color;
              }
            }
          }
        }
      }
      .menu-items {
        &:nth-child(5n+1) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(primary);
              }
            }
          }
        }
  
        &:nth-child(5n+2) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(success);
              }
            }
          }
        }
  
        &:nth-child(5n+3) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(info);
              }
            }
          }
        }
  
        &:nth-child(5n+4) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(warning);
              }
            }
          }
        }
  
        &:nth-child(5n+5) {
          .nav-link {
            .menu-icon {
              i {
                color: theme-color(danger);
              }
            }
          }
        }
      }
    }
}

@media screen and (min-width: 991px) {
  .sidebar {
    -webkit-box-shadow: inset 0px 6px 14px -9px #b4b4b4; 
    box-shadow: inset 0px 6px 14px -9px #b4b4b4;
  }
}
  
/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    //right: -$sidebar-width-lg;
    left: -100%;
    width: 95%;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      //right: 0;
      left: 0;
    }
  }
}