/* Animation Mixins */
@keyframes dropdownAnimation {
    from {
        opacity: 0;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }

    to {
        opacity: 1;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
    }
}
  
.dropdownAnimation {
    animation-name: dropdownAnimation;
    @include animation-duration($action-transition-duration);
    @include animation-fill-mode(both);
}

@mixin transition($settings) {
    -webkit-transition: $settings;
    -moz-transition: $settings;
    -ms-transition: $settings;
    -o-transition: $settings;
    transition: $settings;
}