
.communication {
    .header{
        .sender {
            @extend .ellipsis;
            font-size: .875rem;
        }
        .sent-time {
            font-size: .75rem;
        }
        .message-preview{
            font-size: small;
        }
    }
}