/* Buttons */

.btn {
    font-size: $btn-font-size;
    line-height: 1;
    font-weight: 600;
    i {
      margin-right: .3125rem;
    }
    &.btn-rounded {
      @include border-radius(1.15rem);
    }
    &.btn-fw {
      min-width: $button-fixed-width;
    }
    &.btn-sm {
      font-size: $btn-font-size-sm;
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }
    &.btn-lg {
      font-size: $btn-font-size-lg;
    }
    &.btn-md {
      font-size: $btn-font-size-md;
      padding: $btn-padding-y-md $btn-padding-x-md;
      min-width: $btn-min-width-md;
      text-transform: uppercase;
    }
    &.btn-xs {
      padding: $btn-padding-y-xs $btn-padding-x-xs;
      font-size: $btn-font-size-xs;
    }
    /* Buttons with only icons */
    &.btn-icon {
      width: 42px;
      height: 42px;
      padding: 0;
    }
    /* Buttons with icon and text */
    &.btn-icon-text {
      .btn-icon-prepend {
        margin-right: .5rem;
      }
      .btn-icon-append {
        margin-left: .5rem;
      }
    }
    &.btn-primary {
      border: none;
      color: black;
      font-weight: bold;
      background: linear-gradient(0deg, $card-bg-expanded-color-start, $card-bg-expanded-color-end);
      &:not(.btn-light):not(.btn-secondary) {
          color:black;
          font-weight: bold;
          &:hover,
          &:focus,
          &:active {
              //background: $input-bg;
              background: linear-gradient(0deg, $card-bg-expanded-color-start, $card-bg-expanded-color-end);
              color: white;
              box-shadow: none;
          }
          // &:focus,
          // &:active {
          //     // background: $value;
          //     // border-color: $value;
          // }
        }
    }
    &.btn-secondary {
      border: none;
      color: white;
      font-weight: bold;
      background: rgb(70,43,128);
      &:not(.btn-light):not(.btn-secondary) {
          color:black;
          font-weight: bold;
          &:hover,
          &:focus,
          &:active {
              //background: $input-bg;
              background: linear-gradient(0deg, $card-bg-expanded-color-start, $card-bg-expanded-color-end);
              color: white;
              box-shadow: none;
          }
          // &:focus,
          // &:active {
          //     // background: $value;
          //     // border-color: $value;
          // }
        }
    }
    &.btn-player {
      background: black;
      color: inherit;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      &:not(.btn-light):not(.btn-secondary) {
        &:hover,
        &:focus,
        &:active {
            //background: $input-bg;
            background: black !important;
            color: white !important;
            box-shadow: none;
        }
      }
    }
 }
  
.btn-group {
    .btn {
        + .btn {
        border-left: 0;
        }
    }
}
  
.btn-toolbar {
    .btn-group {
        +.btn-group {
        @extend .ml-2 !optional;
        }
    }
}

/* inverse buttons */
@each $color, $value in $theme-colors {
    // .btn-inverse-#{$color} {
    //     @include button-inverse-variant($value);
    // }
    .btn-#{$color} {
        &:not(.btn-light):not(.btn-secondary) {
          color: map-get($theme-colors,"secondary");
          font-weight: 500;
          &:hover,
          &:focus,
          &:active {
              color: inherit;
          }
          &:focus,
          &:active {
              background: $value;
              border-color: $value;
          }
        }
    }
}