/* Tables */

.table-container {
  padding: 0.5rem 1rem;
  border-radius: 1.2rem;
  background: $sidebar-color-start;
}

.table-responsive {
  padding-bottom: 1rem;
}

.table {
  margin-bottom: 0;
  color: inherit; 
  > :not(:first-child) {
    border-top: none;
  }
  thead {
    th {
      border-top: 0;
      padding: 1rem;
      border-bottom-width: 1px;
      font-family: $type1;
      font-weight: $font-weight-bold;
      i{
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    padding: 1rem;
    vertical-align: middle;
    //font-size: $default-font-size;
    font-size: $table-font-size;
    line-height: 1;
    // img {
    //   // width: 30px;
    //   // height: 30px;
    //   border-radius: 100%;
    // }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless{
    border: none;
    tr,td,th{
      border: none;
    }
  }
  &.table-ranking {
    thead tr{
      &.ranking-head-1{
        th:nth-child(3),
        th:nth-child(4) {  
          border-left-width: 1px;
          border-bottom: none;
        }
        th:last-child {
          border-left-width: 1px;
          border-bottom: none;
          border-right-width: 1px;
        }
      }
      &.ranking-head-2{
        th:nth-child(1),
        th:nth-child(4),
        th:nth-child(7) {  
          border-left-width: 1px;
        }
        th:last-child {
          border-right-width: 1px;
        }
      }
    }
  }
  &.table-player-stats{
    thead tr{
      &.player-stats-head-1{
        th {  
          border-left-width: 1px;
          border-bottom: none;
        }
        th:nth-child(1) {
          border-left: none;
          border-bottom-width: 1px !important;
          border-bottom: inherit;
        }
        th:nth-child(3),
        th:nth-child(4),
        th:nth-child(12){
          border-bottom-width: 1px !important;
          border-bottom: inherit;
        }
        th:last-child {
          border-left-width: 1px;
          border-right-width: 1px;
          border-bottom-width: 1px !important;
          border-bottom: inherit;
        }
      }
      &.player-stats-head-2{
        th {  
          border-left-width: 1px;
        }
        th:last-child {
          border-right-width: 1px;
        }
      }
    }
  }
  &.table-player-games-stats{
    thead tr{
      &.player-games-stats-head-1{
        th {  
          border-left-width: 1px;
          border-bottom: none;
        }
        th:nth-child(1) {
          border-left: none;
          border-bottom-width: 1px !important;
          border-bottom: inherit;
        }
        th:nth-child(2),
        th:nth-child(3),
        th:nth-child(4),
        th:nth-child(5),
        th:nth-child(11),
        th:nth-child(12){
          border-bottom-width: 1px !important;
          border-bottom: inherit;
        }
        th:last-child {
          border-left-width: 1px;
          border-right-width: 1px;
          border-bottom-width: 1px !important;
          border-bottom: inherit;
        }
      }
      &.player-games-stats-head-2{
        th {  
          border-left-width: 1px;
        }
        th:last-child {
          border-right-width: 1px;
        }
      }
    }
  }
  &.table-communications {
    background: $sidebar-color-start;
    tbody tr:last-child td {
      border-bottom: none;
    }
  }
  &.table-roster{
    thead th{
      padding: 1rem 0.5rem;
    }
  }
}

.table-text-bold {
  font-weight: bold;
}