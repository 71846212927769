body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

body {
  //background-image: url($content-bg-image);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed; /* Assures image stays in place */
  background-position: center; /* Controls your image position */
}

#background-video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;
  width: calc(100% - #{$sidebar-width-lg});
  //transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;

  &.full-page-wrapper {
    min-height: 100vh;
    width: 100%;
    padding-top: 0;

    .main-panel {
      padding-top: 0;

      .content-wrapper {
        padding: 0;

        > div {
          height: 100%;
        }
      }
    }
  }

  @media (max-width:992px) {
    width: 100%;
  }
}

.main-panel {
  //background: linear-gradient(90deg, $main-panel-color-start, $main-panel-color-end);
  background: $main-panel-color-bg;
  border-top-right-radius: 45px;
  //transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: 100%;
  min-height: calc(100vh - #{$navbar-height});
  height: 100vh;
  //padding-top: $navbar-height;
  padding-top: 20px;
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
    padding-top: $navbar-height;
  }
  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  // background: $content-bg-color;
  color: map-get($theme-colors, "secondary");
  padding: $content-padding;
  width: 100%;
  height: 100%;
  @include flex-grow(1);
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    padding: $content-padding-responsive;
  }
}

.container-scroller {
  overflow: hidden;
  display: flex;
  position: relative;

  // background: linear-gradient(90deg, #141414, #282828f2);
  // border-top-right-radius: 45px;
  @media (min-width: 1200px) {
    width: calc(100% - #{$content-right-side-spacer});
  }
}

.scrollable-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-right: 0.75rem;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-family: $type1;
  font-weight: $font-weight-light;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;
  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}
.page-title {
  color: $body-color;
  font-size: 1.125rem;
  margin-bottom: 0;
  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    i {
      font-size: .9375rem;
      line-height: 36px;
    }
  }
}

/* Scrollbar CSS */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  padding-left: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
  border: 3px solid transparent;
}

/* File Chooser CSS */
.dropzone {
    padding: 1.15rem;
    text-align: center;
}

/* React Router Dom */
.link-no-decoration {
  text-decoration: none;
  color: inherit;
}
.link-no-decoration:hover {
  color: inherit;
}

/* Progress Bar */

.progress-bar-vertical {
  width: 20px;
  min-height: 60px;
  display: flex;
  align-items: flex-end;
  float: left;

  .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
  }

  &.last-game-progress{
    background-color: transparent;
    border-radius: 0;
  }
}

.last-game-border-top-home {
  border-top-width: 2px !important;
  border-top-color: #f26b8d !important;
  border-top-style: solid;
}

.last-game-border-top-away {
  border-top-width: 2px !important;
  border-top-color: #fa9d68 !important;
  border-top-style: solid;
}

/* Others */

.highlight {
  color: #fa9d68;
}

.stats-icon {
  background: $stats_widget_icon_background;
}

.tooltip-inner {
  text-align: start;
}

/* Head2Head */
.head2head {
  @media (min-width:576px) {
    &.mx-sm-5 {
      margin-right: 6rem !important;
      margin-left: 6rem !important;
    }
  }
  @media (min-width:768px) {
    &.mx-sm-5 {
      margin-right: 9rem !important;
      margin-left: 9rem !important;
    }
  }
}