.calendar {
    box-sizing: border-box;
    font-size: 0.75rem;
    min-width: 250px;

    .calendar-header {
        text-align: center;
        min-height: 2rem;
        line-height: 2rem;
        font-weight: 700;
        display: flex;
        padding-bottom: 0.5rem;

        .previous {
            flex: 1;
            text-align: left;
            margin-left: 1rem;
            &:hover {
                cursor: pointer;
            }
        }

        .next {
            flex: 1;
            text-align: right;
            margin-right: 1rem;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .day-names {
        display: flex;
        flex-wrap: wrap;
        //max-width: 1000px;
        margin: 0 auto;
        align-items: center;
        //background: linear-gradient(90deg, $sidebar-color-start,  $sidebar-color-end);
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        // -webkit-box-shadow: 0px 4px 4px 1px rgb(0 0 0 / 40%); 
        // box-shadow: 0px 4px 4px 1px rgb(0 0 0 / 40%);
      }
    
    .week {
        width: calc(100% / 7);
        height: 44px;
        line-height: 44px;
        text-align: center;
        text-transform: uppercase;
        color: var(--black);
        font-weight: 400;

        div {
            width: 100%;
        }
    }
    .day {
        position: relative;
        width: calc(100% / 7);
        height: 50px;
        display: inline-block;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        z-index: 1;
        text-align: center;

        div:first-child {
            width: 30px;
            height: 30px;
            position: relative;
            //color: inherit;
            z-index: 100;
            line-height: 30px;
            margin: 0 auto;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
        }

        .before {
            color: darken(map-get($theme-colors,"secondary"), 30%);
        }
        
        .selected {
            background: linear-gradient(90deg, $card-bg-expanded-color-start, $card-bg-expanded-color-end);
            color: #000000;
            border-radius: 50%;
        }

        .today {
            color: #000000;
            background-color: $gray-500;
            border-radius: 50%;
        }

        .has-events {
            background: linear-gradient(90deg, $card-bg-expanded-color-start, $card-bg-expanded-color-end);
            height: 0.5rem;
            width: 0.5rem;
            border-radius: 50%;
            margin: 0.3rem auto 0;
        }
    }
}

.calendar-events-list {
    .header {
        text-align: center;
        min-height: 2rem;
        line-height: 2rem;
        font-weight: 700;
    }

    .body {
        min-height: 2rem;

        .event {
            padding: 0.5rem;
            &:hover{
                border: 1px solid map-get($theme-colors,"secondary");
                border-radius: 0.75rem;
                cursor: pointer;
            }
        }

        .title {
            font-size: 1rem;
            font-weight: 700;
        }
        .description {
            padding-bottom: 0.2rem;
        }
        time {
            font-size: 0.75rem;
            font-weight: 700;
        }
    
        address {
            font-size: 0.75rem;
        }
    
        .event-icon {
            width: 3rem;
            text-align: center;
        }
    }

    p {
        margin: 0;
    }

    ul {
        list-style: none;
        padding-left: 0;
    }
}