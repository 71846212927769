.login {
    
    .brand-logo {
        
        img {
            max-width: 100%;
            height: 70px;
            margin: auto;
        }
    }
}