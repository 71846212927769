// /* Dropdowns */

.dropdown {
    .dropdown-toggle{
      border-radius: 1.15rem;
      &.no-caret {
        &::after {
          display: none;
        }
      }
    }
    .dropdown-menu {
      margin-top: .75rem;
      font-size: $default-font-size;
      box-shadow: $dropdown-menu-box-shadow;
      background-color: white;
      .dropdown-item {
        color: black;
        font-size: 1rem;
        padding: .25rem 1.5rem;
        &:active {
          background: initial;
        }
        &:hover {
          //color: $dropdown-item-hover-color;
          color: grey;
        }
      }
      &.sidebar-dropdown {
        &.dropdown-menu-right {
          .rtl & {
            left: 10rem !important;
          }
        }
      }
    }
  }